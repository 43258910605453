import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import { PropostaDescontoComissao } from 'models/PropostaDescontoComissao';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  Row,
} from 'reactstrap';
import api from 'services/api';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import FloatingButton from 'components/FloatingButton';
import { PropostaVersao } from 'models/PropostaVersao';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import formatCurrency from 'utils/formatCurrency';
import * as xlsx from 'xlsx';
import { useQuery } from 'react-query';
import { PropostaContext } from '../..';

import Cabecalho from './components/Cabecalho';
import { DownloadPlanilha } from './components/DownloadPlanilha';
import Precificacao from './components/Precificacao';
import PropostaConfiguracaoMaterial from './components/PropostaConfiguracaoMaterial';
import PropostaMaterialAvulso from './components/PropostaMaterialAvulso';
import Rodape from './components/Rodape';
import ImportarExcel from '../ImportarExcel';
import PropostaMaterialImportado from './components/PropostaMaterialImportado';

interface IMateriais {
  propostaConfiguracoes: PropostaConfiguracao[];
}

interface MateriaisContext {
  valorItens: number | null;
  valorFrete: number | null | undefined;
  valorFinal: number | null;
  valorFinalDolar: number | null;
  setValorItens: (valor: number | null) => void;
  setValorFrete: (valor: number | undefined) => void;
  setValorFinal: (valor: number | null) => void;
  setValorServico: (valor: number | null) => void;
  setValorDesconto: (valor: number | null) => void;
  setValorFinalDolar: (valor: number | null) => void;
  setValorPercentualFrete: (valor: number | null) => void;
  loadingMateriais: boolean;
  setLoadingMateriais: (valor: boolean) => void;
  setTriggerMaterialCheck: (value: boolean) => void;
  isMaterialValid: boolean;
  acessoLimitado: boolean;
  listaDescontoComissao: PropostaDescontoComissao[];
  atualizarPrecificacao: boolean;
  setAtualizarPrecificacao: (value: boolean) => void;
  recalculandoProposta: boolean;
  setRecalculandoProposta: (value: boolean) => void;
  descontoSelecionado: string | null;
  setDescontoSelecionado: (valor: string | null) => void;
  descontoMaximo: number | null;
  setDescontoMaximo: (valor: number | null) => void;
  valorLicenciamento: number | undefined;
  setValorLicenciamento: (valor: number | undefined) => void;
  valorTopografia: number | undefined;
  setValorTopografia: (valor: number | undefined) => void;
  valorOutros: number | undefined;
  setValorOutros: (valor: number | undefined) => void;
  isLicenciamentoPercentual: boolean;
  setIsLicenciamentoPercentual: (value: boolean) => void;
  isTopografiaPercentual: boolean;
  setIsTopografiaPercentual: (value: boolean) => void;
  isOutrosPercentual: boolean;
  setIsOutrosPercentual: (value: boolean) => void;
  isFretePercentual: boolean;
  setIsFretePercentual: (value: boolean) => void;
  valorDolarCambial: number;
  setValorDolarCambial: (valor: number) => void;
  valorDespesaDespachante: number;
  setValorDespesaDespachante: (valor: number) => void;
  valorSeguroFrete: number;
  setValorSeguroFrete: (valor: number) => void;
  valorDolarParametro: number;
  setValorDolarParametro: (valor: number) => void;
  percentualAssistenciaTecnica: number;
  setPercentualAssistenciaTecnica: (valor: number) => void;
  percentualTaxaFlat: number;
  setPercentualTaxaFlat: (valor: number) => void;
  percentualComissao: number;
  setPercentualComissao: (valor: number) => void;
  percentualAjusteComissao: number;
  setPercentualAjusteComissao: (valor: number) => void;
  percentualIndicadorNegocio: number;
  setPercentualIndicadorNegocio: (valor: number) => void;
  percentualComissaoReceber: number;
  setPercentualComissaoReceber: (valor: number) => void;
  percentualServicos: number;
  setPercentualServicos: (valor: number) => void;
  percentualICMS: number;
  setPercentualICMS: (valor: number) => void;
  percentualPISCofins: number;
  setPercentualPISCofins: (valor: number) => void;
  percentualIPI: number;
  setPercentualIPI: (valor: number) => void;
  percentualST: number;
  setPercentualST: (valor: number) => void;
  isSaveRequired: boolean;
  setIsSaveRequired: (value: boolean) => void;
  salvandoDifal: boolean;
  setSalvandoDifal: (value: boolean) => void;
  idBancoFinanciamentoSelecionado: string;
  setIdBancoFinanciamentoSelecionado: (valor: string) => void;
  linhaCreditoSelecionado: number;
  setLinhaCreditoSelecionado: (valor: number) => void;
  formatoBancoSelecionado: string;
  setFormatoBancoSelecionado: (valor: string) => void;
  isPercentualLicenciamento: boolean;
  setIsPercentualLicenciamento: (value: boolean) => void;
  isPercentualTopografia: boolean;
  setIsPercentualTopografia: (value: boolean) => void;
  isPercentualOutros: boolean;
  setIsPercentualOutros: (value: boolean) => void;
  isPercentualFrete: boolean;
  setIsPercentualFrete: (value: boolean) => void;
  bloquearDesconto: boolean;
  setBloquearDesconto: (value: boolean) => void;
  isFinanciado: boolean;
  setIsFinanciado: (value: boolean) => void;
  motivoOutros: string;
  setMotivoOutros: (valor: string) => void;
  triggerUpdateMaterial: () => void;
}

export const MateriaisContext = createContext({} as MateriaisContext);

function Materiais({
  propostaConfiguracoes: pPropostaConfiguracoes,
}: IMateriais): JSX.Element {
  const {
    propostaVersao,
    setPropostaVersao,
    setTriggerMaterialCheck,
    isMaterialValid,
    isObraExterior,
    meioPagamentos,
    permiteModificacao,
    meioPagamentoIdSelecionado,
    setMeioPagamentoIdSelecionado,
    carregaPropostaVersao,
    atualizarTelaProposta,
  } = useContext(PropostaContext);

  const [valorItens, setValorItens] = useState<number | null>(
    propostaVersao.valorItens,
  );
  const [valorFrete, setValorFrete] = useState<number | null | undefined>(
    propostaVersao.valorFrete,
  );
  const [valorFinal, setValorFinal] = useState<number | null>(
    propostaVersao.valorFinal,
  );
  const [valorServico, setValorServico] = useState<number | null>(
    propostaVersao.valorServico,
  );
  const [valorDesconto, setValorDesconto] = useState<number | null>(
    propostaVersao.valorDesconto,
  );
  const [valorFinalDolar, setValorFinalDolar] = useState<number | null>(
    propostaVersao.valorFinalDolar,
  );
  const [valorPercentualFrete, setValorPercentualFrete] = useState<
    number | null
  >(propostaVersao.percentualFrete);
  const [loadingMateriais, setLoadingMateriais] = useState(true);

  const [acessoLimitado, setAcessoLimitado] = useState(true);

  const [acessoLimitadoListaMateriais, setAcessoLimitadoListaMateriais] =
    useState(true);

  const [atualizarPrecificacao, setAtualizarPrecificacao] = useState(true);

  const [listaDescontoComissao, setListaDescontoComissao] = useState<
    PropostaDescontoComissao[]
  >([]);

  const [propostaConfiguracoes, setPropostaConfiguracoes] = useState<
    PropostaConfiguracao[]
  >([]);

  const [recalculandoProposta, setRecalculandoProposta] = useState(false);
  const [descontoSelecionado, setDescontoSelecionado] = useState<string | null>(
    '',
  );
  const [salvando, setSalvando] = useState(false);
  const [salvandoPercentuais, setSalvandoPercentuais] = useState(false);
  const [descontoMaximo, setDescontoMaximo] = useState<number | null>(null);
  const [valorLicenciamento, setValorLicenciamento] = useState<number>();
  const [valorTopografia, setValorTopografia] = useState<number>();
  const [valorOutros, setValorOutros] = useState<number>();
  const [isLicenciamentoPercentual, setIsLicenciamentoPercentual] =
    useState(false);
  const [isTopografiaPercentual, setIsTopografiaPercentual] = useState(false);
  const [isOutrosPercentual, setIsOutrosPercentual] = useState(false);
  const [isFretePercentual, setIsFretePercentual] = useState<boolean>(false);
  const [valorDolarCambial, setValorDolarCambial] = useState(0);
  const [valorDolarParametro, setValorDolarParametro] = useState(0);
  const [percentualAssistenciaTecnica, setPercentualAssistenciaTecnica] =
    useState(0);
  const [percentualTaxaFlat, setPercentualTaxaFlat] = useState(0);
  const [percentualComissao, setPercentualComissao] = useState(0);
  const [percentualAjusteComissao, setPercentualAjusteComissao] = useState(0);
  const [percentualIndicadorNegocio, setPercentualIndicadorNegocio] =
    useState(0);
  const [percentualComissaoReceber, setPercentualComissaoReceber] = useState(0);

  const [percentualServicos, setPercentualServicos] = useState(0);
  const [percentualICMS, setPercentualICMS] = useState(0);
  const [percentualPISCofins, setPercentualPISCofins] = useState(0);
  const [percentualIPI, setPercentualIPI] = useState(0);
  const [percentualST, setPercentualST] = useState(0);
  const [difal, setDifal] = useState('do Cliente');
  const [valorDespesaDespachante, setValorDespesaDespachante] = useState(0);
  const [valorSeguroFrete, setValorSeguroFrete] = useState(0);
  const [isSaveRequired, setIsSaveRequired] = useState(false);
  const [salvandoDifal, setSalvandoDifal] = useState(false);
  const [atualizandoInfosBancarias, setAtualizandoInfosBancarias] =
    useState(false);
  const [idBancoFinanciamentoSelecionado, setIdBancoFinanciamentoSelecionado] =
    useState<string>('');

  const [linhaCreditoSelecionado, setLinhaCreditoSelecionado] =
    useState<number>(0);
  const [formatoBancoSelecionado, setFormatoBancoSelecionado] = useState('');
  const [isPercentualLicenciamento, setIsPercentualLicenciamento] =
    useState(false);
  const [isPercentualTopografia, setIsPercentualTopografia] = useState(false);
  const [isPercentualOutros, setIsPercentualOutros] = useState(false);
  const [isPercentualFrete, setIsPercentualFrete] = useState(false);
  const [bloquearDesconto, setBloquearDesconto] = useState(false);
  const [isFinanciado, setIsFinanciado] = useState(false);
  const [motivoOutros, setMotivoOutros] = useState('');

  // Modal meta
  const [formModalState, setFormModalState] = useState(false);
  const [metaValorSemDesconto, setMetaValorSemDesconto] = useState(0);
  const [metaDesconto, setMetaDesconto] = useState(0);
  const [metaValor, setMetaValor] = useState('0');

  const [MateriaisImportados, setMateriaisImportados] = useState<any[]>([]);

  async function getMateriaisImportados() {
    const response = await api.get(
      `/proposta/versao/${propostaVersao.id}/material-importado`,
    );
    return response.data;
  }

  async function buscarMateriaisImportados() {
    const dados = await getMateriaisImportados();
    setMateriaisImportados(dados);
  }

  const { data: dataMateriaisAvulsos, isLoading } = useQuery(
    ['proposta-configuracao-materiais-importados', propostaVersao.id],
    async () => {
      const data = await getMateriaisImportados();
      return data;
    },
  );

  // const [precosDesatualizados, setPrecosDesatualizados] = useState(false);

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 28,
      },
    });
    setAcessoLimitado(response.data);

    const response2 = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 60,
      },
    });
    setAcessoLimitadoListaMateriais(response2.data);
  }

  useEffect(() => {
    getAcessoLimitadoPrograma();
  }, []);

  async function buscarInfoProposta() {
    const response = await api.get(`/proposta/versao/${propostaVersao.id}`);
    setPropostaVersao(response.data);
  }

  async function buscarConfiguracoes() {
    const response = await api.get(
      `/proposta/versao/${propostaVersao.id}/configuracoes`,
    );

    setPropostaConfiguracoes(response.data);
  }

  /* useEffect(() => {
    async function carregarListaPreco() {
      const response = await api.get(
        `/proposta/versao/${propostaVersao.id}/validar-precos`,
      );
      setPrecosDesatualizados(response.data.desatualizado ?? false);
    }
    carregarListaPreco();
  }, []); */

  async function buscarListaDescontos() {
    const response2 = await api.get(
      `/proposta-perfil-aprovacao/min-max-desconto/${propostaVersao.id}`,
    );

    setDescontoMaximo(response2.data?.descontoMaximo ?? 0);
  }

  async function alteraDifal(novoValor: string) {
    try {
      setSalvandoDifal(true);
      Swal.fire({
        icon: 'info',
        text: `Salvando difal...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const response = await api.post(
        `/proposta/versao/${propostaVersao.id}/precificacao/calculo-irr`,
        { difalPorConta: novoValor },
      );
      setDifal(novoValor);
      const { data } = response;
      if (!data) return;
      setPercentualTaxaFlat(data.percentualTaxaFlat);
      setPercentualICMS(data.percentualICMS);
      setPercentualPISCofins(data.percentualPISCofins);
      setPercentualIPI(data.percentualIPI);
      setPercentualST(data.percentualST);
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  async function recalculaProposta() {
    try {
      setValorPercentualFrete(0);
      setRecalculandoProposta(true);
      Swal.fire({
        icon: 'info',
        text: `Recalculando proposta...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const response = await api.post(
        `/proposta/versao/${propostaVersao.id}/recalcular`,
      );
      const data = response.data as PropostaVersao;
      setValorItens(data.valorItens);
      setValorFrete(data.percentualFrete);
      setValorFinal(data.valorFinal);
      setValorFinalDolar(data.valorFinalDolar);

      setValorServico(data.valorServico);
      setValorDesconto(data.valorDesconto);
      setValorPercentualFrete(data.percentualFrete);
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  async function atualizarProposta() {
    Swal.fire({
      icon: 'info',
      text: `Atualizando informações da proposta`,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
    });

    try {
      await api.post(`/proposta/versao/${propostaVersao.id}/reprocessar`);

      Swal.close();
    } catch (error: any) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: error?.response?.data?.message,
      });
    }
  }

  async function triggerUpdateMaterial() {
    await atualizarProposta();
    await recalculaProposta();

    await buscarMateriaisImportados();
    setTriggerMaterialCheck(true);
    Swal.close();
    toast.success('Proposta atualizada');
  }

  async function checkAprovacaoDescontoNecessaria() {
    const descontoSalvar = Number(
      descontoSelecionado?.replaceAll('.', '').replaceAll(',', '.') ?? 0,
    );

    if (descontoSalvar > Number(descontoMaximo ?? 0)) {
      const valor = await Swal.fire({
        title: `Desconto precisa de aprovação`,
        html: `<div style='text-align: justify!important; text-justify: inter-word;'>
            O percentual informado (${descontoSalvar}%) excede o máximo permitido (<u>menor que</u> ${descontoMaximo}%) para o perfil.
            <br /><br />Ao prosseguir, o efetivo uso desse percentual passará por uma aprovação.
            A proposta ficará bloqueada enquanto aguarda uma resposta dos aprovadores.
            <br /><br />Você será informado por e-mail caso seja aprovado ou reprovado. Deseja prosseguir?</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, prosseguir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      });
      return { aprovacao: true, salvar: valor.isConfirmed };
    }

    return { aprovacao: false, salvar: true };
  }

  async function handleUpdateDescontoSelecionado(descontoEnviar: string) {
    const descontoSalvar = Number(
      descontoEnviar?.replaceAll('.', '').replaceAll(',', '.') ?? 0,
    );

    Swal.fire({
      icon: 'info',
      text: `Atualizando o desconto...`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    try {
      const retorno = await api.put(`/proposta/versao/${propostaVersao.id}`, {
        desconto: descontoSalvar,
      });

      return retorno.data;
    } catch (error: any) {
      let mensagem = error?.response?.data?.message;

      if (!mensagem) {
        mensagem = error?.message;
      }
      console.log(mensagem ?? 'Não foi possível salvar o desconto');
      throw error;
    }
  }

  async function salvaPercentuais() {
    let valorDolarSalvar = null;

    if (isObraExterior)
      valorDolarSalvar = valorDolarCambial || valorDolarParametro;

    Swal.fire({
      icon: 'info',
      text: `Salvando os percentuais...`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    try {
      setSalvandoPercentuais(true);
      const response = await api.put(
        `/proposta/versao/${propostaVersao.id}/precificacao`,
        {
          percentualLicenciamento: isPercentualLicenciamento
            ? valorLicenciamento
            : undefined,
          percentualTopografia: isPercentualTopografia
            ? valorTopografia
            : undefined,
          percentualOutros: isPercentualOutros ? valorOutros : undefined,
          percentualFrete: isPercentualFrete ? valorFrete : undefined,
          valorLicenciamento: !isPercentualLicenciamento
            ? valorLicenciamento
            : undefined,
          valorTopografia: !isPercentualTopografia
            ? valorTopografia
            : undefined,
          valorOutros: !isPercentualOutros ? valorOutros : undefined,
          valorFrete: !isPercentualFrete ? valorFrete : undefined,
          percentualAssistenciaTecnica,
          percentualTaxaFlat,
          percentualComissao,
          percentualAjusteComissao,
          percentualIndicadorNegocio,
          percentualComissaoReceber,
          percentualServicos,
          percentualICMS,
          percentualPISCofins,
          percentualIPI,
          percentualST,
          financiado: isFinanciado,
          valorDespesaDespachante: isObraExterior
            ? valorDespesaDespachante
            : null,
          valorSeguroFrete: isObraExterior ? valorSeguroFrete : null,
          valorDolar: valorDolarSalvar,
          motivoOutros: valorOutros ? motivoOutros : null,
        },
      );

      setIsSaveRequired(false);

      await alteraDifal(difal);
    } catch (error: any) {
      console.log(error?.response?.data?.message);
      throw error;
    }
  }

  async function carregaDadosPercentuais() {
    const response = await api.get(
      `/proposta/versao/${propostaVersao.id}/precificacao`,
    );

    const precificacao = response.data?.[0];

    if (!precificacao) {
      return;
    }

    const {
      percentualLicenciamento,
      percentualTopografia,
      percentualOutros,
      percentualFrete,
      valorLicenciamento: valorLicenciamentoData,
      valorTopografia: valorTopografiaData,
      valorOutros: valorOutrosData,
      valorFrete: valorFreteData,
    } = precificacao;

    setValorLicenciamento(percentualLicenciamento ?? valorLicenciamentoData);
    setValorTopografia(percentualTopografia ?? valorTopografiaData);
    setValorOutros(percentualOutros ?? valorOutrosData);
    setValorFrete(percentualFrete); // Fixando em percentual, por enquanto

    setIsLicenciamentoPercentual(Boolean(percentualLicenciamento));
    setIsTopografiaPercentual(Boolean(percentualTopografia));
    setIsOutrosPercentual(Boolean(percentualOutros));
    setIsFretePercentual(true); // POr conta de bug, vamos fixar em percentual apenas por enquanto

    setPercentualAssistenciaTecnica(precificacao.percentualAssistenciaTecnica);
    setPercentualComissao(precificacao.percentualComissao);
    setPercentualAjusteComissao(precificacao.percentualAjusteComissao);
    setPercentualIndicadorNegocio(precificacao.percentualIndicadorNegocio);
    setPercentualComissaoReceber(precificacao.percentualComissaoReceber);
    setPercentualServicos(precificacao.percentualServicos ?? 3);
    setPercentualTaxaFlat(precificacao.percentualTaxaFlat);
    setPercentualICMS(precificacao.percentualICMS);
    setPercentualPISCofins(precificacao.percentualPISCofins);
    setPercentualIPI(precificacao.percentualIPI);
    setPercentualST(precificacao.percentualST);
    setPercentualST(precificacao.percentualST);
    setMotivoOutros(precificacao.motivoOutros ?? '');

    if (isObraExterior) {
      setValorDespesaDespachante(precificacao.valorDespesaDespachante);
      setValorDolarCambial(precificacao.valorDolar);
      setValorSeguroFrete(precificacao.valorSeguroFrete);
    }

    setAtualizarPrecificacao(false);
  }

  async function salvaInfosBancarias() {
    Swal.fire({
      icon: 'info',
      text: `Salvando informações bancárias...`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    try {
      setAtualizandoInfosBancarias(true);
      await api.put(`/proposta/versao/${propostaVersao.id}/financiamento`, {
        meioPagamentoId: meioPagamentoIdSelecionado,
        banco: idBancoFinanciamentoSelecionado,
        formatoBanco: formatoBancoSelecionado,
        propostaLinhaCredito: linhaCreditoSelecionado,
      });
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  async function salvarValores() {
    try {
      if (Number(valorOutros) > 0 && (!motivoOutros || motivoOutros === '')) {
        Swal.fire({
          icon: 'warning',
          text: 'Quando o campo Outros for preenchido, é obrigatório informar um motivo.',
        });
        return;
      }
      let salvarDesconto: { aprovacao: boolean; salvar: boolean } = {
        aprovacao: false,
        salvar: false,
      };
      if (!bloquearDesconto)
        salvarDesconto = await checkAprovacaoDescontoNecessaria();

      await salvaInfosBancarias();
      await salvaPercentuais();

      // let recalcular = true;
      if (salvarDesconto.salvar) {
        await handleUpdateDescontoSelecionado(String(descontoSelecionado ?? 0));
      }

      await recalculaProposta();

      if (salvarDesconto.aprovacao === true) atualizarTelaProposta(true);

      setAtualizarPrecificacao(true);
      await buscarInfoProposta();
      Swal.close();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: error?.response?.data?.message,
      });
    } finally {
      setAtualizandoInfosBancarias(false);
      setSalvandoDifal(false);
      setRecalculandoProposta(false);
      setTriggerMaterialCheck(true);
      setSalvando(false);
      setSalvandoPercentuais(false);
    }
  }

  async function modalAtingirMeta() {
    setMetaValorSemDesconto((valorFinal ?? 0) + (valorDesconto ?? 0));
    setMetaDesconto(Number(descontoSelecionado?.replace(',', '.') ?? 0));
    setMetaValor(formatCurrency(valorFinal ?? 0, 2));

    setFormModalState(!formModalState);
  }

  async function handleConfirmarAlteracaoDesconto() {
    const confirmacao = await Swal.fire({
      title: `Atenção`,
      html: `Ao prosseguir, os valores vão substituir o desconto atual. O processo de recálculo da proposta será disparado.
      Caso o desconto não esteja na alçada do usuário, os processos de aprovação serão disparados.`,
      icon: 'warning',
      confirmButtonText: `Sim, prosseguir`,
      cancelButtonText: `Não`,
      showCancelButton: true,
    });

    const resultado = confirmacao.isConfirmed;

    if (resultado) {
      // Disparar processo para atualizar os dados
      setDescontoSelecionado(String(metaDesconto ?? 0).replace('.', ','));
      setFormModalState(!formModalState);

      try {
        await handleUpdateDescontoSelecionado(
          String(metaDesconto ?? 0).replace('.', ','),
        );
        await recalculaProposta();
        await buscarInfoProposta();
        await carregaDadosPercentuais();
        Swal.close();
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: error?.response?.data?.message,
        });
      } finally {
        setRecalculandoProposta(false);
        setTriggerMaterialCheck(true);
        setSalvando(false);
        setSalvandoPercentuais(false);
      }
    }
  }

  useEffect(() => {
    if (atualizarPrecificacao === true) carregaDadosPercentuais();
  }, [atualizarPrecificacao]);

  useEffect(() => {
    buscarListaDescontos();
    buscarConfiguracoes();
    buscarMateriaisImportados();
  }, []);

  async function callBackConfiguracoes() {
    buscarConfiguracoes();
  }

  return (
    <MateriaisContext.Provider
      value={{
        valorItens,
        valorFrete,
        valorFinal,
        valorFinalDolar,
        setValorItens,
        setValorFrete,
        setValorFinal,
        setValorServico,
        setValorDesconto,
        setValorPercentualFrete,
        setValorFinalDolar,
        loadingMateriais,
        setLoadingMateriais,
        setTriggerMaterialCheck,
        isMaterialValid,
        acessoLimitado,
        listaDescontoComissao,
        atualizarPrecificacao,
        setAtualizarPrecificacao,
        recalculandoProposta,
        setRecalculandoProposta,
        descontoSelecionado,
        setDescontoSelecionado,
        descontoMaximo,
        setDescontoMaximo,
        valorLicenciamento,
        setValorLicenciamento,
        valorTopografia,
        setValorTopografia,
        valorOutros,
        setValorOutros,
        isLicenciamentoPercentual,
        setIsLicenciamentoPercentual,
        isTopografiaPercentual,
        setIsTopografiaPercentual,
        isOutrosPercentual,
        setIsOutrosPercentual,
        isFretePercentual,
        setIsFretePercentual,
        valorDolarCambial,
        setValorDolarCambial,
        valorDespesaDespachante,
        setValorDespesaDespachante,
        valorSeguroFrete,
        setValorSeguroFrete,
        valorDolarParametro,
        setValorDolarParametro,
        percentualAssistenciaTecnica,
        setPercentualAssistenciaTecnica,
        percentualTaxaFlat,
        setPercentualTaxaFlat,
        percentualComissao,
        setPercentualComissao,
        percentualAjusteComissao,
        setPercentualAjusteComissao,
        percentualIndicadorNegocio,
        setPercentualIndicadorNegocio,
        percentualComissaoReceber,
        setPercentualComissaoReceber,
        percentualServicos,
        setPercentualServicos,
        percentualICMS,
        setPercentualICMS,
        percentualPISCofins,
        setPercentualPISCofins,
        percentualIPI,
        setPercentualIPI,
        percentualST,
        setPercentualST,
        isSaveRequired,
        setIsSaveRequired,
        salvandoDifal,
        setSalvandoDifal,
        idBancoFinanciamentoSelecionado,
        setIdBancoFinanciamentoSelecionado,
        linhaCreditoSelecionado,
        setLinhaCreditoSelecionado,
        formatoBancoSelecionado,
        setFormatoBancoSelecionado,
        isPercentualLicenciamento,
        setIsPercentualLicenciamento,
        isPercentualTopografia,
        setIsPercentualTopografia,
        isPercentualOutros,
        setIsPercentualOutros,
        isPercentualFrete,
        setIsPercentualFrete,
        bloquearDesconto,
        setBloquearDesconto,
        isFinanciado,
        setIsFinanciado,
        motivoOutros,
        setMotivoOutros,
        triggerUpdateMaterial,
      }}
    >
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-codigo-domsge"
                >
                  Valor Original Sem Desconto:
                </label>
                <br />
                {formatCurrency(metaValorSemDesconto, 2)}
              </FormGroup>

              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-codigo-domsge"
                >
                  Valor Desejado:
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-codigo-domsge"
                  type="text"
                  value={metaValor.replace('R$', '').trim()}
                  onBlur={text => {
                    const convertido = String(text.target.value ?? '0')
                      .replaceAll('.', '')
                      .replaceAll(',', '.');
                    setMetaValor(formatCurrency(Number(convertido), 2));
                  }}
                  onChange={text => {
                    const convertido = String(text.target.value ?? '0')
                      .replaceAll('.', '')
                      .replaceAll(',', '.');

                    setMetaValor(convertido.replace('.', ','));

                    if (!Number.isNaN(Number(`${convertido}`)))
                      setMetaDesconto(
                        100 -
                          (Number(convertido ?? 0) * 100) /
                            metaValorSemDesconto,
                      );
                    else setMetaDesconto(metaValorSemDesconto);
                  }}
                />
              </FormGroup>

              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-codigo-domsge"
                >
                  Desconto a ser aplicado (%):
                </label>
                <br />
                {metaDesconto}%
              </FormGroup>

              {/* metaValor +
                arredondaDecimais(
                  (metaValorSemDesconto * metaDesconto) / 100,
                  2,
                ) */}

              <Row>
                <Col sm="6">
                  <Button
                    className="btn-icon btn-2 "
                    color="default"
                    type="button"
                    outline
                    onClick={() => setFormModalState(!formModalState)}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col sm="6">
                  <Button
                    className="btn-icon btn-2"
                    color="primary"
                    type="button"
                    onClick={handleConfirmarAlteracaoDesconto}
                  >
                    Aplicar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Modal>

      {!isMaterialValid && (
        <Row className="mb-4">
          <Col sm="12" xs="12">
            <Alert color="danger">
              Uma ou mais configurações associadas possuem materiais sem valor.
              Algumas ações estarão bloqueadas até a resolução do valor dos
              materiais.
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm="6">
          <ImportarExcel
            propostaVersaoId={propostaVersao.id}
            tipo="Telemetria"
          />
          <ImportarExcel propostaVersaoId={propostaVersao.id} tipo="BM" />
          <ImportarExcel
            propostaVersaoId={propostaVersao.id}
            tipo="Outros"
            carregarFinalidade
          />
        </Col>
        <Col sm="6">
          <Button
            className="btn-icon btn-2 mr-3 m-3 float-right"
            color="default"
            type="button"
            outline
            size="sm"
            onClick={salvarValores}
          >
            <span className="btn-inner--text">
              Salvar e recalcular proposta
            </span>
          </Button>
          <Button
            className="btn-icon btn-2 mr-3 m-3 float-right"
            color="info"
            type="button"
            outline
            size="sm"
            disabled={
              recalculandoProposta || bloquearDesconto || !permiteModificacao
            }
            title="Definir meta de desconto para alcançar determinado valor final"
            onClick={modalAtingirMeta}
          >
            <span className="btn-inner--text">Atingir Meta</span>
          </Button>
        </Col>
      </Row>

      <Cabecalho />

      <Precificacao />

      <Row className="mb-4">
        <Col sm="10" xs="8" />
        <Col sm="2" xs="4">
          <DownloadPlanilha
            className="float-right"
            acessoLimitado={acessoLimitadoListaMateriais}
            propostaConfiguracoes={propostaConfiguracoes}
          />
        </Col>
      </Row>

      {propostaConfiguracoes.map(propostaConfiguracao => (
        <PropostaConfiguracaoMaterial
          key={propostaConfiguracao.id}
          propostaConfiguracao={propostaConfiguracao}
          atualizar={callBackConfiguracoes}
        />
      ))}

      <PropostaMaterialAvulso />

      <PropostaMaterialImportado
        materiaisImportados={MateriaisImportados ?? []}
      />

      <Rodape
        valorPercentualFrete={valorPercentualFrete}
        valorDesconto={valorDesconto}
        valorItens={valorItens}
        valorFrete={Number(propostaVersao.valorFrete)}
      />
    </MateriaisContext.Provider>
  );
}

export default Materiais;
