/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { ReactNode, useEffect, useState } from 'react';
import UserHeader from 'components/Headers/UserHeader';
import toast from 'react-hot-toast';
import { addDays } from 'date-fns';

import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter, {
  AlignAction,
  DeleteAction,
  ImageSpec,
} from 'quill-blot-formatter';
import {
  Button,
  FormGroup,
  InputGroup,
  Modal,
  Card,
  CardBody,
  Form,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
  Col,
  Container,
  CardHeader,
  Label,
  Table,
} from 'reactstrap';

import api from 'services/api';
import Swal from 'sweetalert2';
import Header from 'components/Headers/Header.js';
import { PropostaVersao } from 'models/PropostaVersao';
import Proposta from 'models/Proposta';
import { PropostaMeioPagamento } from 'models/PropostaMeioPagamento';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import { ProdutoProposta } from 'models/ProdutoProposta';
import Parametros from 'models/Parametros';
import { useQueries, useQuery } from 'react-query';
import OrcamentoProduto from 'models/OrcamentoProduto';
import FichaTecnica from 'models/FichaTecnica';
import BancoFinanciamento from 'models/BancoFinanciamento';

import formatCurrency from 'utils/formatCurrency';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import FloatingButton from 'components/FloatingButton';
import { FaPrint } from 'react-icons/fa';
import { Th, ContainerButtons } from '../Proposta/components/Parcelas/styles';
import {
  ColQuestao,
  DivSub,
  InputAta,
  LabelItem,
  LabelQuestao,
  LabelTema,
  Labelcabecalho,
  SpanTitulo,
  TituloDestaque,
  Td,
  Geral,
  CabecalhoFixoAta,
  TituloAta,
  TemaAta,
  CabecalhoEditAta,
  ColunaDados,
  ConteudoDocumentoAta,
  DivFormatada,
} from './style';
import AtaModel from '../../../models/AtaBriefing';
import LancesBalanco from '../ConfiguracaoEtapas/components/Resultado/components/organisms/DadosCalculados/components/LancesBalanco';
// import 'assets/vendor/quill/dist/quill.core.css';
import './styles.css';
import 'react-quill/dist/quill.snow.css';
import Resultados from '../Proposta/components/Resultados';

type Props = {
  formModalState?: any;
  setFormModalState?: any;
  labelUsuario?: any;
};

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  meioPagamentos: PropostaMeioPagamento[];
  meioPagamentoIdSelecionado: number;
  propostaConfiguracao: PropostaConfiguracao;
  produtoProposta: ProdutoProposta;
}

interface PapeisAta {
  id: number;
  papel: string;
  pessoa: string;
}
interface IPropostaVersaoParcelas extends PropostaVersaoParcelas {
  novo?: boolean;
}

const AtaProposta: React.FC = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const [orcamentoProduto, setOrcamentoProduto] = useState<OrcamentoProduto>();
  const {
    proposta,
    propostaVersao,
    meioPagamentos,
    meioPagamentoIdSelecionado,
    propostaConfiguracao,
    produtoProposta,
  } = location.state;
  const fichaCadastrada = propostaVersao.PropostaConfiguracao?.[0].fichaTecnica;
  const orcamento = propostaVersao.PropostaConfiguracao?.[0].orcamento;
  const orcamentoId = propostaVersao.PropostaConfiguracao?.[0].orcamentoId;
  const ownerProposta = propostaVersao.proposta?.usuario?.nome;
  const propostaVersaoParcelas = propostaVersao.PropostaVersaoParcelas;
  const propData = propostaVersao.dataProposta;
  const {
    ncm,
    finame,
    nome: descricaoProduto,
    nomeProposta,
  } = orcamentoProduto?.produto || {};
  const [isSaving, setIsSaving] = useState(false);
  const [NroConfigStorage, setNroConfigStorage] = useState('');
  const [fichaTecnica, setFichaTecnica] = useState<FichaTecnica>();
  const locationState = location.state as any;
  const [ataBriefing, setAtaBriefing] = useState([] as AtaModel[]);
  const [ataBriefingEditing, setAtaBriefingEditing] = useState({} as AtaModel);
  const [papeisAta, setPapeisAta] = useState([] as PapeisAta[]);
  const [validadeProposta, setValidadeProposta] = useState(0);
  const [texto, setTexto] = useState('');
  const [bancosFinanciamento, setBancoFinanciamento] = useState<
    BancoFinanciamento[]
  >([]);

  const contextConfiguracao = locationState?.telaConfig || false;
  const meioSelecionado = meioPagamentos.filter(
    meioPagamento => meioPagamento.id === meioPagamentoIdSelecionado,
  );

  const quantidadeLances = fichaTecnica?.FichaTecnicaLance?.reduce(
    (total, item) => total + item.quantidade,
    0,
  );

  const isTipoFinanciado = meioSelecionado?.[0]?.financiado ?? false;
  const isParalelo = meioSelecionado?.[0]?.descricao === 'Paralelo' ?? false;
  const isRecursoProprio =
    meioSelecionado?.[0]?.descricao?.toUpperCase() === 'RECURSO PRÓPRIO' ??
    false;

  const meioPagamentoSelecionado = meioPagamentos.filter(
    (meioPagamento: any) =>
      (isTipoFinanciado &&
        isParalelo &&
        meioPagamento.descricao !== 'Paralelo') ||
      (isTipoFinanciado &&
        !isParalelo &&
        meioPagamento.financiado === true &&
        meioPagamento.descricao !== 'Paralelo') ||
      (!isTipoFinanciado &&
        meioPagamento.financiado === false &&
        meioPagamento.descricao !== 'Paralelo'),
  );

  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const isFinanciados = meioPagamentos.find(
    meioPagamento => meioPagamento.id === meioPagamentoIdSelecionado,
  )?.financiado;
  // const formats = ['align', 'float'];
  Quill.register('modules/blotFormatter', BlotFormatter);

  async function listaDadosAta() {
    if (propostaVersao.id) {
      const response = await api.get(
        `/ata-briefing/lista-por-proposta/${propostaVersao.id}`,
      );

      setAtaBriefing(response.data);
      setAtaBriefingEditing(response.data);
      setTexto(response.data.documento);
    }
  }

  async function listaDadosEditor() {
    if (ataBriefingEditing.id) {
      const response = await api.get(
        `/ata-briefing/conteudo-documento/${ataBriefingEditing.id}`,
      );

      setTexto(response.data.documento);
    }
  }

  async function listaPapeisAta() {
    if (propostaVersao.id) {
      const response = await api.post(
        `/integracao/niveis-indicadores/tipos-indicadores-ata/`,
        {
          propostaVersaoId: propostaVersao.id,
        },
      );

      setPapeisAta(response.data);
    }
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaDadosAta();
    listaDadosEditor();
    listaPapeisAta();
  }, []);

  const { data: queryParametros, isLoading } = useQuery(
    `parametros`,
    async () => {
      async function getData(): Promise<Parametros> {
        const response = await api.get('/parametros');
        return response.data;
      }

      const dataParametros = await getData();
      return dataParametros;
    },
  );

  useEffect(() => {
    if (queryParametros) {
      setValidadeProposta(queryParametros.validadeProposta ?? 0);
    }
  }, [queryParametros]);

  const { data: cacheFichaTecnica } = useQuery(
    `ficha-tecnica-orcamento-${orcamentoId}`,
    async () => {
      if (orcamentoId) {
        const response = await api.get(
          `/ficha-tecnica/orcamento-versao/novo/${orcamentoId}`,
        );
        return response.data;
      }
      return false;
    },
  );

  useEffect(() => {
    if (cacheFichaTecnica) {
      setFichaTecnica(cacheFichaTecnica);
    }
  }, [cacheFichaTecnica]);

  const fichaTecnicaQueries = useQueries(
    propostaVersao.PropostaConfiguracao?.map(item => ({
      queryKey: ['ficha-tecnica-orcamento', item.orcamentoId],
      queryFn: async () => {
        if (item.orcamentoId) {
          const response = await api.get(
            `/ficha-tecnica/orcamento-versao/novo/${item.orcamentoId}`,
          );
          return response.data;
        }
        return null;
      },
      enabled: !!item.orcamentoId,
    })) || [],
  );

  const { data: cacheOrcamentoProduto } = useQuery(
    `configuracao-${orcamentoId}-produto`,
    async () => {
      if (orcamentoId) {
        const response = await api.get(`/orcamento/versao/${orcamentoId}`, {
          params: { completo: true },
        });
        const orcamentoProdutoData: OrcamentoProduto =
          response.data.OrcamentoProduto[0];
        return orcamentoProdutoData;
      }
      return false;
    },
  );

  useEffect(() => {
    if (cacheOrcamentoProduto) {
      setOrcamentoProduto(cacheOrcamentoProduto);
    }
  }, [cacheOrcamentoProduto]);

  const { data: queryBancos } = useQuery(
    `banco-financiamento`,
    async () => {
      const response = await api.get(`/integracao/banco`);
      return response.data;
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    if (queryBancos) {
      setBancoFinanciamento(queryBancos);
    }
  }, [queryBancos]);

  function formataMoeda(valor?: number | null) {
    if (valor === null || valor === undefined) {
      return '';
    }
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor || 0);
  }

  function handleUpdate(itemDesconto: AtaModel) {
    setAtaBriefingEditing(itemDesconto);
  }

  async function handleSave() {
    // eslint-disable-next-line prettier/prettier
    const { id } = ataBriefingEditing;

    if (!propostaVersao.id) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }
    setIsSaving(true);

    if (ataBriefingEditing.id) {
      await api.put(`/ata-briefing/${ataBriefingEditing.id}`, {
        propostaVersaoId: propostaVersao.id,
        documento: texto,
        dataInicio: ataBriefingEditing.dataInicio,
        dataFim: ataBriefingEditing.dataFim,
        local: ataBriefingEditing.local,
        horario: ataBriefingEditing.horario,
        participantes: ataBriefingEditing.participantes,
        comCopia: ataBriefingEditing.comCopia,
        supervisorComercial: ataBriefingEditing.supervisorComercial,
        executivoVendas: ataBriefingEditing.executivoVendas,
        representanteGestor: ataBriefingEditing.representanteGestor,
        analiseNegocio: ataBriefingEditing.analiseNegocio,
        nomeCliente: ataBriefingEditing.nomeCliente,
        briefingClienteObra: ataBriefingEditing.briefingClienteObra,
        formaPagamento: ataBriefingEditing.formaPagamento,
        propostaCliente: ataBriefingEditing.propostaCliente,
        cronograma: ataBriefingEditing.cronograma,
        briefingConcorrentes: ataBriefingEditing.briefingConcorrentes,
        propostaFockinkCliente: ataBriefingEditing.propostaFockinkCliente,
        cronogramaFockink: ataBriefingEditing.cronogramaFockink,
        observacao: ataBriefingEditing.observacao,
        definicao: ataBriefingEditing.definicao,
        tema: ataBriefingEditing.tema,
        indicador: ataBriefingEditing.indicador,
      });
    }

    toast.success('Registro concluído');
    setIsSaving(false);
    setAtaBriefingEditing({} as AtaModel);
    listaDadosAta();
    listaDadosEditor();
  }

  useEffect(() => {
    if (isLoadingContent === true)
      toast.loading('Carregando conteúdo...', { position: 'top-right' });
    else toast.dismiss();
  }, [isLoadingContent]);

  async function gerarReport() {
    const retorno = await api.get(
      `/ata-briefing/gerar-pdf?propostaVersaoId=${propostaVersao.id}&enviarPdf=N&retornarHtml=N`,
      {},
    );

    return retorno.data;
  }

  async function getLinkReport() {
    const retorno = await api.get(
      `/ata-briefing/gerar-link-pdf?propostaVersaoId=${propostaVersao.id}`,
      {},
    );

    return retorno.data;
  }

  async function handleImprimeAta() {
    setIsLoadingContent(true);

    const openInNewTab = (url: string): void => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    };

    await gerarReport();
    const retornoLink = await getLinkReport();

    openInNewTab(retornoLink.FileUrl);

    setIsLoadingContent(false);

    /* history.push({
      pathname: '/admin/ata-impressao',
      state: {
        proposta,
        propostaVersao,
        produtoProposta,
        propostaVersaoParcelas,
        meioPagamentos,
        meioPagamentoIdSelecionado,
        propostaConfiguracao,
        orcamentoProduto,
        validadeProposta,
        fichaTecnica,
      },
    }); */
  }
  const dataFormatadaAta = propData
    ? new Intl.DateTimeFormat('pt-BR').format(new Date(propData))
    : null;
  const bancoSelecionado = bancosFinanciamento?.filter(
    item => item.codigo === propostaVersao.banco,
  );

  const nomeBanco = bancoSelecionado[0]?.razao_social;

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button
  ];

  function createMarkup(tipo: string) {
    if (tipo === 'indicador')
      return { __html: `${ataBriefingEditing.indicador ?? ''}` };

    return { __html: `${ataBriefingEditing.documento ?? ''}` };
  }

  function Parcelas(): JSX.Element {
    return (
      <>
        <Table responsive bordered aria-readonly>
          <thead>
            <tr>
              <Th className="text-center">Percentual</Th>
              <Th className="text-center">Data prevista</Th>
              <Th className="text-center">Meio de pagamento</Th>
              <Th className="text-right">Total</Th>
              <Th className="text-center">Evento</Th>
            </tr>
          </thead>
          <tbody>
            {propostaVersaoParcelas
              .sort(function (
                a: PropostaVersaoParcelas,
                b: PropostaVersaoParcelas,
              ) {
                if (a.dataPrevista && b.dataPrevista) {
                  return (
                    new Date(a.dataPrevista).getTime() -
                    new Date(b.dataPrevista).getTime()
                  );
                }
                if (!a.dataPrevista && !b.dataPrevista) {
                  return a.id - b.id;
                }
                if (!a.dataPrevista) {
                  return 1;
                }
                return -1;
              })
              ?.map(
                (
                  propostaVersaoParcela: IPropostaVersaoParcelas,
                  index: number,
                ) => {
                  const {
                    id,
                    percentual,
                    dataPrevista,
                    meioPagamentoId,
                    valor,
                    observacao,
                  } = propostaVersaoParcela;

                  const idRow = index + 1;

                  let dataUsar = '';

                  if (propostaVersaoParcela.dataPrevista) {
                    const data = new Date(propostaVersaoParcela.dataPrevista);
                    data.setHours(data.getHours() + 4);

                    dataUsar = propostaVersaoParcela.dataPrevista
                      ? new Intl.DateTimeFormat('pt-BR').format(data)
                      : '';
                  }

                  return (
                    <tr key={idRow}>
                      <Td className="px-sm-2">{String(percentual)}%</Td>
                      <Td className="px-sm-2">{dataUsar}</Td>
                      <Td className="px-sm-2">
                        {meioSelecionado?.[0]?.descricao ?? ''}
                      </Td>
                      <Td className="text-right">
                        <Label className="text-muted">
                          <strong>{formatCurrency(valor)}</strong>
                        </Label>
                      </Td>
                      <Td className="text-right">{String(observacao)}</Td>
                    </tr>
                  );
                },
              )}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <Form>
            <Row>
              <Col className="col">
                <CardBody>
                  <Geral>
                    <CabecalhoFixoAta>
                      <Row>
                        <Col sm="4">
                          Proposta:&nbsp;{proposta.id}&nbsp;
                          &nbsp;&nbsp;&nbsp;Versão: {propostaVersao.versao}
                        </Col>
                        <Col sm="8">
                          <span className="span-titulo">ATA DE REUNIÃO</span>
                        </Col>
                      </Row>
                    </CabecalhoFixoAta>
                    <TituloAta>
                      <TituloDestaque>
                        DOCUMENTO CONFIDENCIAL E DE CIRCULAÇÃO INTERNA –
                        VALIDADE DESTA ATA {validadeProposta} DIAS
                      </TituloDestaque>
                    </TituloAta>
                    <TemaAta>
                      <Row sm="12" md="12" xs="12">
                        <Col sm="2" className="mt-2 mb-2">
                          <LabelTema>TEMA:</LabelTema>
                        </Col>

                        <Col sm="10" className="mt-2 mb-2">
                          <InputGroup className="input-group-alternative invalid-feedback">
                            <InputAta
                              className="input-group-alternative"
                              type="text"
                              value={ataBriefingEditing.tema}
                              disabled={propostaVersao.status.id === 3}
                              onChange={text =>
                                setAtaBriefingEditing({
                                  ...ataBriefingEditing,
                                  tema: text.target.value,
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </TemaAta>

                    <CabecalhoEditAta>
                      <Row sm="4" md="4" xs="12">
                        <ColunaDados sm="4" md="4">
                          <Row className="p-2 mt-1 ml-1">
                            <ColQuestao>
                              <Labelcabecalho>Data:&nbsp;</Labelcabecalho>{' '}
                            </ColQuestao>
                            <Col>
                              <InputGroup className="input-group-alternative invalid-feedback">
                                <InputAta
                                  className="input-group-alternative"
                                  type="text"
                                  value={
                                    ataBriefingEditing.dataInicio
                                      ? String(dataFormatadaAta)
                                      : String(
                                          new Intl.DateTimeFormat(
                                            'pt-BR',
                                          ).format(new Date()),
                                        )
                                  }
                                  disabled
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="p-2 mt-1 ml-1">
                            <ColQuestao>
                              <Labelcabecalho>Horário:&nbsp;</Labelcabecalho>
                            </ColQuestao>
                            <Col>
                              <InputGroup className="input-group-alternative invalid-feedback">
                                <InputAta
                                  className="input-group-alternative"
                                  type="textarea"
                                  value={ataBriefingEditing.horario}
                                  disabled={propostaVersao.status.id === 3}
                                  onChange={text =>
                                    setAtaBriefingEditing({
                                      ...ataBriefingEditing,
                                      horario: text.target.value,
                                    })
                                  }
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="p-2 mt-1 ml-1">
                            <ColQuestao>
                              <Labelcabecalho>Local:&nbsp;</Labelcabecalho>
                            </ColQuestao>
                            <Col>
                              <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i
                                        className="ni ni-align-center"
                                        style={{
                                          alignSelf: 'start',
                                          marginTop: '0.3rem',
                                        }}
                                      />
                                    </InputGroupText>
                                  </InputGroupAddon>

                                  <Input
                                    placeholder="Local:"
                                    rows="3"
                                    type="textarea"
                                    value={ataBriefingEditing.local}
                                    disabled={propostaVersao.status.id === 3}
                                    onChange={text =>
                                      setAtaBriefingEditing({
                                        ...ataBriefingEditing,
                                        local: text.target.value,
                                      })
                                    }
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </ColunaDados>
                        <ColunaDados sm="4" md="4">
                          <Row className="p-2 mt-1 ml-1">
                            <ColQuestao>
                              <Labelcabecalho>
                                Participantes:&nbsp;
                              </Labelcabecalho>
                            </ColQuestao>
                            <Col>
                              <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i
                                        className="ni ni-align-center"
                                        style={{
                                          alignSelf: 'start',
                                          marginTop: '0.3rem',
                                        }}
                                      />
                                    </InputGroupText>
                                  </InputGroupAddon>

                                  <Input
                                    placeholder="Participantes:"
                                    rows="6"
                                    type="textarea"
                                    value={ataBriefingEditing.participantes}
                                    disabled={propostaVersao.status.id === 3}
                                    onChange={text =>
                                      setAtaBriefingEditing({
                                        ...ataBriefingEditing,
                                        participantes: text.target.value,
                                      })
                                    }
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </ColunaDados>
                        <ColunaDados sm="4" md="4">
                          <Row className="p-2 mt-1 ml-1">
                            <ColQuestao>
                              <Labelcabecalho>C/C:&nbsp;</Labelcabecalho>
                            </ColQuestao>
                            <Col>
                              <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i
                                        className="ni ni-align-center"
                                        style={{
                                          alignSelf: 'start',
                                          marginTop: '0.3rem',
                                        }}
                                      />
                                    </InputGroupText>
                                  </InputGroupAddon>

                                  <Input
                                    placeholder="C/C:"
                                    rows="6"
                                    type="textarea"
                                    value={ataBriefingEditing.comCopia}
                                    disabled={propostaVersao.status.id === 3}
                                    onChange={text =>
                                      setAtaBriefingEditing({
                                        ...ataBriefingEditing,
                                        comCopia: text.target.value,
                                      })
                                    }
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </ColunaDados>
                      </Row>
                    </CabecalhoEditAta>
                    <ConteudoDocumentoAta>
                      {/* }
                      <Row>
                        <Col>
                          <LabelQuestao>
                            Supervisor Comercial: &nbsp;
                          </LabelQuestao>
                          <SpanTitulo>
                            {ataBriefingEditing.supervisorComercial}
                          </SpanTitulo>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <LabelQuestao>
                            Executivo de Vendas: &nbsp;
                          </LabelQuestao>
                          <SpanTitulo>
                            {ataBriefingEditing.executivoVendas}
                          </SpanTitulo>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <LabelQuestao>
                            Representante/Gestor: &nbsp;
                          </LabelQuestao>
                          <SpanTitulo>
                            {ataBriefingEditing.representanteGestor ??
                              ownerProposta}
                          </SpanTitulo>
                        </Col>
                      </Row>
                            { */}
                      <div className="space-items mt-6">
                        {propostaVersao.statusId === 3 && (
                          <div
                            dangerouslySetInnerHTML={createMarkup('indicador')}
                          />
                        )}
                        {propostaVersao.statusId !== 3 &&
                          papeisAta.map(campo => (
                            <>
                              <Row className="space-items mt-2">
                                <Col>
                                  <LabelItem>{campo.papel}: &nbsp;</LabelItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <SpanTitulo>{campo.pessoa}</SpanTitulo>
                                </Col>
                              </Row>
                            </>
                          ))}
                      </div>
                      <div className="space-items mt-6">
                        <Row>
                          <Col>
                            <LabelItem>Nome do Cliente: &nbsp;</LabelItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <SpanTitulo>
                              {proposta.pessoa?.razaoSocial}
                            </SpanTitulo>
                          </Col>
                        </Row>
                      </div>
                      <div className="space-items mt-3">
                        <Row>
                          <Col sm="2">
                            <LabelItem>Forma de pagamento:&nbsp;</LabelItem>
                          </Col>
                          {isFinanciados && (
                            <>
                              <Col sm="2">
                                <LabelItem>Banco:&nbsp;</LabelItem>
                              </Col>
                              <Col sm="2">
                                <LabelItem>Formato:&nbsp;</LabelItem>
                              </Col>
                              <Col sm="2">
                                <LabelItem>Linha de Crédito:&nbsp;</LabelItem>
                              </Col>
                            </>
                          )}
                        </Row>
                        <Row>
                          <Col sm="2">
                            <SpanTitulo>
                              {meioSelecionado?.[0]?.descricao ?? ''}
                            </SpanTitulo>
                          </Col>
                          {isFinanciados && (
                            <>
                              <Col sm="2">
                                <SpanTitulo>{nomeBanco}</SpanTitulo>
                              </Col>
                              <Col sm="2">
                                <SpanTitulo>
                                  {propostaVersao?.formatoBanco}
                                </SpanTitulo>
                              </Col>
                              <Col sm="2">
                                <SpanTitulo>
                                  {propostaVersao?.PropostaLinhaCredito?.nome}
                                </SpanTitulo>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                      {propostaVersao.PropostaConfiguracao?.map(
                        (item, index) => {
                          const { data: ftUsar } =
                            fichaTecnicaQueries[index] || {};

                          const marca = 'Marca Fockink';

                          const incluirAspersorFinal =
                            ftUsar?.incluirAspersorFinal;
                          const areaTotalIrrigadaCAF =
                            ftUsar?.areaTotalIrrigadaCAF;
                          const areaEfetivaIrrigadaSAF =
                            ftUsar?.areaEfetivaIrrigadaSAF;
                          const usaAspersorFinal: boolean =
                            String(incluirAspersorFinal).toUpperCase() ===
                              'SIM' ||
                            String(incluirAspersorFinal).toUpperCase() === 'S';

                          const valorArea = usaAspersorFinal
                            ? Number(areaTotalIrrigadaCAF ?? 0)
                            : Number(areaEfetivaIrrigadaSAF ?? 0);

                          const sist =
                            ftUsar?.FichaTecnicaBombeamento?.[0]?.motor ||
                            'NÃO INFORMADO';
                          const qtdeLances = ftUsar?.FichaTecnicaLance?.reduce(
                            (total: any, aux: { quantidade: any }) =>
                              total + aux.quantidade,
                            0,
                          );
                          const r1 = Number(ftUsar?.aspersor1Raio ?? 0);
                          const r2 = Number(ftUsar?.aspersor2Raio ?? 0);
                          const txtUsar = `${
                            nomeProposta ?? descricaoProduto
                          }, ${marca}, sendo ${sist} com
                            ${qtdeLances} torres de sustentação, raio
                            ${
                              Number(ftUsar?.comprimentoTotalTubos ?? 0) +
                              (r1 > r2 ? r1 : r2)
                            }M para área irrigada de ${valorArea} HA, composto de:`;

                          const titulo = `Configuração: ${
                            item?.orcamentoId ?? 'Não especificado'
                          } ${
                            ftUsar?.pivoCentral
                              ? ` | Equipamento ${ftUsar?.pivoCentral}`
                              : ''
                          }`;
                          return (
                            <div
                              key={item.orcamentoId}
                              className="space-items mt-3"
                            >
                              <Row>
                                <Col>
                                  <h2>
                                    <SpanTitulo>{titulo}</SpanTitulo>
                                  </h2>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <LabelItem>
                                    Proposta Fockink ao cliente:&nbsp;
                                  </LabelItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  {/* <SpanTitulo>{textoProduto}</SpanTitulo> */}
                                  <SpanTitulo>{txtUsar}</SpanTitulo>
                                </Col>
                              </Row>
                              <Row>
                                {ftUsar && (
                                  <DivFormatada className="space-items mt-3 mb-2 mr-4">
                                    <Row>
                                      <Col>
                                        <LancesBalanco
                                          fichaTecnica={ftUsar as FichaTecnica}
                                        />
                                      </Col>
                                    </Row>
                                  </DivFormatada>
                                )}
                              </Row>
                            </div>
                          );
                        },
                      )}
                      {/* <div className="space-items mt-3">
                        <Row>
                          <Col>
                            <LabelItem>
                              Proposta Fockink ao cliente:&nbsp;
                            </LabelItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <SpanTitulo>{textoProduto}</SpanTitulo>
                          </Col>
                        </Row>
                        <Row>
                          {fichaTecnica && (
                            <DivFormatada className="space-items mt-3 mb-2 mr-4">
                              <Row>
                                <Col>
                                  <LancesBalanco
                                    fichaTecnica={fichaTecnica as FichaTecnica}
                                  />
                                </Col>
                              </Row>
                            </DivFormatada>
                          )}
                        </Row>
                      </div> */}
                      <div className="space-items mt-3">
                        <Row>
                          <Col sm="1">
                            <LabelItem>Valor:&nbsp;</LabelItem>
                          </Col>
                          <Col sm="4">
                            <LabelItem>
                              (Desconto&nbsp;{propostaVersao.desconto}%)
                            </LabelItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <SpanTitulo>
                              {formataMoeda(propostaVersao.valorFinal)}
                            </SpanTitulo>
                          </Col>
                        </Row>
                      </div>
                      <div className="space-items mt-3">
                        <Row>
                          <Col>
                            <LabelItem>Cronograma:&nbsp;</LabelItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <SpanTitulo>
                              {ataBriefingEditing.cronograma}
                            </SpanTitulo>
                          </Col>
                        </Row>
                      </div>
                      <div className="space-items mt-3">
                        <Row>
                          <Col>
                            <Parcelas />
                          </Col>
                        </Row>
                      </div>

                      <div className="mt-3">
                        <Row>
                          <Col>
                            <LabelItem>Dre:&nbsp;</LabelItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Resultados
                              propostaVersao={propostaVersao}
                              apenasSintetica
                            />
                          </Col>
                        </Row>
                      </div>
                      {propostaVersao.status.id !== 3 ? (
                        <DivSub>
                          <Row>
                            <Col>
                              <ReactQuill
                                value={texto}
                                style={{ overflow: 'scroll', height: '400px' }}
                                onChange={setTexto}
                                placeholder="Editar conteúdo..."
                                theme="snow"
                                modules={{
                                  blotFormatter: {
                                    overlay: {
                                      style: { border: '2px solid red' },
                                    },
                                    align: {
                                      aligner: {
                                        applyStyle: false,
                                      },
                                      toolbar: {
                                        mainClassName:
                                          'blot-formatter__toolbar',
                                        mainStyle: {
                                          display: 'none',
                                        },
                                        buttonStyle: {
                                          display: 'none',
                                        },
                                        svgStyle: { display: 'none' },
                                      },
                                    },
                                    toolbar: {
                                      // whether or not users can deselect an alignment. it's up to you to set the initial alignment
                                      allowDeselect: false,
                                      addButtonSelectStyle: false,
                                    },
                                  },
                                  toolbar: toolbarOptions,
                                }}
                              />
                            </Col>
                          </Row>
                        </DivSub>
                      ) : (
                        <div dangerouslySetInnerHTML={createMarkup('')} />
                      )}

                      <div className="space-items mt-2 mb-2 mr-4">
                        <Row>
                          <Col>
                            <Button
                              className="my-4"
                              color="primary"
                              type="button"
                              hidden={propostaVersao.status.id === 3}
                              disabled={
                                isSaving || propostaVersao.status.id === 3
                              }
                              onClick={handleSave}
                            >
                              {isSaving ? 'Salvando...' : 'Salvar'}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <FloatingButton
                        className="hide-on-print"
                        disabled={isLoadingContent}
                        onClick={handleImprimeAta}
                      >
                        <FaPrint />
                      </FloatingButton>
                    </ConteudoDocumentoAta>
                  </Geral>
                </CardBody>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    </>
  );
};

export default AtaProposta;
