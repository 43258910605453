import { useContext, useEffect, useState } from 'react';
import { Col, Row, Table } from 'reactstrap';

import { PropostaMeioPagamento } from 'models/PropostaMeioPagamento';
import api from 'services/api';
import formatCurrency from 'utils/formatCurrency';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import { PropostaImpressaoContext } from '../..';
import {
  CondicaoPagamentoRecursoRow,
  Container,
  Label,
  Td,
  Th,
  Typography,
} from './styles';

export default function Parcelas(): JSX.Element {
  const { parcelas, isPropostaExterior, valorDolar, propostaVersao } =
    useContext(PropostaImpressaoContext);
  const [meioPagamentos, setMeioPagamentos] = useState<PropostaMeioPagamento[]>(
    [],
  );
  const [idmeioPagamento, setIdmeioPagamento] = useState(0);
  const meioPagamentoIdSelecionado = propostaVersao.meioPagamento;
  const meioSelecionado = meioPagamentos.filter(
    meioPagamento => meioPagamento.id === meioPagamentoIdSelecionado?.id,
  );

  // 10 decimais
  const divisaoPercentual = 10000000000;

  const isParalelo = meioSelecionado?.[0]?.descricao === 'Paralelo' ?? false;

  const valorTotal =
    parcelas.reduce(
      // eslint-disable-next-line prettier/prettier
    (total, item) => total + ((item?.valor ?? 0) * 100),
      0,
    ) / 100;

  const totalPercentualV =
    parcelas.reduce(
      // eslint-disable-next-line prettier/prettier
    (total, item) => total + ((item.valor ?? 0) * 100),
      0,
    ) / 100;

  const financiamento =
    parcelas
      .filter((item: any) => item.meioPagamentoId === 2)
      .reduce((accumulator, object) => {
        // eslint-disable-next-line prettier/prettier
      return accumulator + ((object.valor ?? 0) * 100);
      }, 0) / 100;

  const recursoProprioV =
    parcelas
      .filter((item: any) => item.meioPagamentoId === 1)
      .reduce((accumulator, object) => {
        // eslint-disable-next-line prettier/prettier
      return accumulator + ((object.valor ?? 0) * 100);
      }, 0) / 100;

  const recursoProprio = (recursoProprioV / valorTotal) * 100;
  const percentualFinanciado = (financiamento / valorTotal) * 100;
  const totalPercentual = (totalPercentualV / valorTotal) * 100;
  const percentualParalelo = percentualFinanciado + recursoProprio;

  useEffect(() => {
    async function carregaMeioPagamentos(): Promise<void> {
      const response = await api.get('/proposta/meio-pagamento');
      setMeioPagamentos(response.data);
    }

    carregaMeioPagamentos();
    setIdmeioPagamento(
      Number(parcelas.find(parcela => parcela)?.meioPagamentoId),
    );
  }, []);

  useEffect(() => {
    setIdmeioPagamento(
      Number(parcelas.find(parcela => parcela)?.meioPagamentoId),
    );
  }, [parcelas]);

  function formataValor(valor?: number | null) {
    if (!valor && !valorDolar) return formatCurrency(0, 2, isPropostaExterior);

    if (isPropostaExterior) {
      if (!valorDolar) return formatCurrency(0, 2, true);

      // Converte para Dólar
      const convertido = Number(valor ?? 0) / valorDolar;
      return formatCurrency(convertido, 2, true);
    }
    return formatCurrency(valor, 2);
  }

  function getTotalMeioPagamento(meioPagamentoId: number): number {
    return (
      parcelas
        .filter(parcela => parcela.meioPagamentoId === meioPagamentoId)
        // eslint-disable-next-line prettier/prettier
        .reduce((total, item) => total + ((item.valor ?? 0) * 100), 0) / 100
    );
  }

  function Totais() {
    return (
      <Row>
        <Col>
          <Label>CONDIÇÃO DE PAGAMENTO:</Label>
        </Col>
        <Col>
          <Label>Valor Total: {formataValor(valorTotal)}</Label>
        </Col>
        <Col>
          <Label>{isParalelo ? percentualParalelo : totalPercentual}%</Label>
        </Col>
      </Row>
    );
  }

  function RecursoTotais({ className }: { className: string }): JSX.Element {
    const parcelaSelecionada = propostaVersao.meioPagamento;
    return (
      <CondicaoPagamentoRecursoRow className={className}>
        {meioPagamentos
          .filter(
            parcela =>
              parcela.id === idmeioPagamento ||
              (parcelaSelecionada?.descricao?.toUpperCase() ===
                'Paralelo'.toUpperCase() &&
                parcelaSelecionada.id !== parcela.id),
          )
          .map(meioPagamento => {
            const meioPagamentoValor = getTotalMeioPagamento(meioPagamento.id);

            return (
              <Col key={meioPagamento.id}>
                {!isParalelo ? (
                  <>
                    <Label className="mr-4">{meioPagamento.descricao}:</Label>
                    <Label>{formataValor(meioPagamentoValor)}</Label>
                  </>
                ) : (
                  <>
                    <Label className="mr-4">
                      {meioPagamento.descricao}: REF
                    </Label>
                    <Label>
                      {meioPagamento.id === 1
                        ? arredondaDecimais(recursoProprio, 2)
                        : arredondaDecimais(percentualFinanciado, 2)}
                      %
                    </Label>
                  </>
                )}
              </Col>
            );
          })}
        <Col md="1">
          <Label>{isParalelo ? percentualParalelo : totalPercentual}%</Label>
        </Col>
      </CondicaoPagamentoRecursoRow>
    );
  }

  function CondicaoPagamentoParcelas({
    className,
  }: {
    className: string;
  }): JSX.Element {
    const parcelaSelecionada = propostaVersao.meioPagamento;
    return (
      <CondicaoPagamentoRecursoRow className={className}>
        <Table borderless>
          <thead>
            <tr>
              <Th>
                <h4>Evento</h4>
              </Th>
              {meioPagamentos
                .filter(
                  parcela =>
                    parcela.id === idmeioPagamento ||
                    (parcelaSelecionada?.descricao?.toUpperCase() ===
                      'Paralelo'.toUpperCase() &&
                      parcelaSelecionada.id !== parcela.id),
                )
                .map(meioPagamento => (
                  <Th key={meioPagamento.id}>
                    <h4>{meioPagamento.descricao}</h4>
                  </Th>
                ))}
              <Th>
                <h4>Percentual</h4>
              </Th>
              {/*
              <Th>
                <h4>Data Prevista</h4>
              </Th>
                */}
            </tr>
          </thead>
          <tbody>
            {parcelas.map(parcela => {
              const dataParcelaPrevista = `${
                String(parcela.dataPrevista)?.split('T')[0]
              }T21:00:00`;
              return (
                <tr key={parcela.id}>
                  <Td>
                    <h4>{parcela.observacao}</h4>
                  </Td>
                  {meioPagamentos
                    .filter(
                      parcelaItem =>
                        parcelaItem.id === idmeioPagamento ||
                        (parcelaSelecionada?.descricao?.toUpperCase() ===
                          'Paralelo'.toUpperCase() &&
                          parcelaSelecionada.id !== parcelaItem.id),
                    )
                    .map(meioPagamento => {
                      const parcelaValor =
                        meioPagamento.id === parcela.meioPagamentoId
                          ? parcela.valor
                          : 0;
                      return (
                        <Th key={meioPagamento.id}>
                          <h4>{formataValor(parcelaValor)}</h4>
                        </Th>
                      );
                    })}
                  <Td>
                    <h4>{parcela.percentual}%</h4>
                  </Td>
                  {/*
                  <Td>
                    <h4>
                      {new Date(dataParcelaPrevista).toLocaleDateString()}
                    </h4>
                  </Td>
                  */}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <Td>
                <h4>Total</h4>
              </Td>
              {meioPagamentos
                .filter(
                  parcelaItem =>
                    parcelaItem.id === idmeioPagamento ||
                    (parcelaSelecionada?.descricao?.toUpperCase() ===
                      'Paralelo'.toUpperCase() &&
                      parcelaSelecionada.id !== parcelaItem.id),
                )
                .map(meioPagamento => {
                  const meioPagamentoValor = getTotalMeioPagamento(
                    meioPagamento.id,
                  );
                  return (
                    <Th key={meioPagamento.id}>
                      <h4>{formataValor(meioPagamentoValor)}</h4>
                    </Th>
                  );
                })}
              <Td>
                <h4>{isParalelo ? percentualParalelo : totalPercentual}%</h4>
              </Td>
            </tr>
          </tfoot>
        </Table>
      </CondicaoPagamentoRecursoRow>
    );
  }

  return (
    <Container>
      <Row>
        <Typography variant="h6" className="float-left mb-3">
          <strong>CRONOGRAMA FÍSICO / FINANCEIRO</strong>
        </Typography>
      </Row>

      <Totais />
      <RecursoTotais className="mt-3" />
      <CondicaoPagamentoParcelas className="mt-3" />
    </Container>
  );
}
